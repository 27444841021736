import 'jquery-ui-bundle/jquery-ui.css'
import 'jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.css'
import 'bootstrap-select/dist/css/bootstrap-select.css'
import 'nprogress/nprogress.css'
import 'font-awesome/css/font-awesome.css'
import 'vendor/react-select.scss'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import 'core-js'
import 'whatwg-fetch'
import 'script-loader!jquery'
import 'script-loader!jquery-ujs'
import 'script-loader!jquery-ui-bundle/jquery-ui'
import 'script-loader!jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon'
import 'script-loader!jquery-ui-timepicker-addon/dist/i18n/jquery-ui-timepicker-de'
import 'script-loader!vendor/datepicker-de'
import 'script-loader!bootstrap-sass/assets/javascripts/bootstrap'
import 'script-loader!bootstrap-select/dist/js/bootstrap-select'
import 'script-loader!nprogress'
import 'script-loader!vendor/plugins/download-file'
import 'script-loader!vendor/plugins/sweetalert2'
import 'script-loader!vendor/plugins/upload-file'
import 'script-loader!vendor/plugins/base'
import 'script-loader!services/toggle-checkbox'
import copy from 'copy-to-clipboard'
// this file should only include a shared code (node_modules or vendor folder)


window.$(() => {
  $('a[data-clipboard-copy]').on('click', e => {
    e.preventDefault();
    copy(e.target.dataset.clipboardCopy);
  });
});
